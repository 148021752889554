import React from "react"
import SusHero from "./SusHero"
import TrustedHomepage from "../Home/TrustedHomepage"

const SustainabilityPage = () => {
    return (
        <div>
            <SusHero />
            <TrustedHomepage />
        </div>
    )
}

export default SustainabilityPage
