import React from "react"
import HeroAbout from "./HeroAbout"
import AboutMissionVission from "./AboutMissionVission"
import AboutTeam from "./AboutTeam"
import TrustedHomepage from "../Home/TrustedHomepage"

const AboutPage = () => {
    return (
        <section>
            <HeroAbout />
            <AboutMissionVission />
            <AboutTeam />
            <TrustedHomepage />
        </section>
    )
}

export default AboutPage
