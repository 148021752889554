import React from "react"
import ContactHome from "./ContactHome"
import ContactFaq from "./ContactFaq"

const ContactPage = () => {
    return (
        <div>
            <ContactHome />
            <ContactFaq />
        </div>
    )
}

export default ContactPage
